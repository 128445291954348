<!--
 * @Description: 系统管理账号列表
-->

<template>
  <page-header-wrapper>
    <ContentLayout>
      <FilterForm slot="top" ref="FilterForm" @search="handleSearch"></FilterForm>
      <div class="btn_wrapper mb-10">
        <a-button type="primary" v-auth:EDIT_USER icon="plus" @click="handleAddItem">新增账号</a-button>
      </div>
      <MainTable class="table_area_comp" ref="MainTable" :getFilter="getFilter"></MainTable>
    </ContentLayout>
  </page-header-wrapper>
</template>

<script>
import FilterForm from './components/FilterForm.vue'
import MainTable from './components/MainTable.vue'

export default {
  name: 'SystemAcount',
  components: {
    FilterForm,
    MainTable
  },
  data() {
    return {}
  },
  methods: {
    handleAddItem() {
      this.$refs.MainTable.handleAddEditItem()
    },
    getFilter() {
      return this.$refs.FilterForm.form
    },
    handleSearch() {
      this.$refs.MainTable.fetchData()
    }
  }
}
</script>

<style lang="scss" scoped>
.btn_wrapper {
  text-align: right;
}

.table_area_comp {
  flex: 1;
  display: flex;
  flex-direction: column;

  ::v-deep .custom_a_table_wrapper {
    flex: 1;
  }
}
</style>

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { title: "编辑权限" },
      on: { ok: _vm.handleSubmit },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "main_content" },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "form", attrs: { model: _vm.form } },
              "a-form-model",
              _vm.formLayout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "选择角色" } },
                [
                  _c("RoleSelector", {
                    model: {
                      value: _vm.form.roleIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleIds", $$v)
                      },
                      expression: "form.roleIds",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Description: 编辑权限
-->
<template>
  <a-modal v-model="show" title="编辑权限" @ok="handleSubmit">
    <div class="main_content">
      <a-form-model :model="form" ref="form" v-bind="formLayout">
        <a-form-model-item label="选择角色">
          <RoleSelector v-model="form.roleIds"></RoleSelector>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import DEFAULT_FORM_LAYOUT from '@/config/formLayout'
import ApiController from '../api/account'

export default {
  data() {
    return {
      value: [],
      show: false,
      form: {
        roleIds: []
      },
      formLayout: DEFAULT_FORM_LAYOUT
    }
  },
  methods: {
    async handleShow(item) {
      const roleIds = item.authRolevms.map((_role) => _role.id)
      this.show = true
      this.form = { roleIds }
      this.item = item
      await this.$nextTick()
      this.$refs.form.clearValidate()
    },
    async handleSubmit() {
      const params = {
        id: this.item.id,
        ...this.form
      }
      try {
        this.loading = true
        await ApiController.assignRole(params)
        this.$message.success('编辑权限成功!')
        this.$emit('success')
        this.show = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.role_select {
  width: 100% !important;
}
</style>

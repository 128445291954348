<!--
 * @Description: 筛选区域
-->
<template>
  <a-card class="form_wrapper">
    <a-form-model :model="form" ref="form" v-bind="layout">
      <FilterLayout @reset="reset" @search="handleSearch">
        <a-form-model-item prop="realname" label="用户名称">
          <a-input v-model="form.realname" placeholder="请输入用户名称"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="phone" label="联系电话">
          <a-input v-model="form.phone" placeholder="请输入联系电话"></a-input>
        </a-form-model-item>

        <a-form-model-item prop="roles" label="用户角色">
          <RoleSelector v-model="form.roles"></RoleSelector>
        </a-form-model-item>

        <a-form-model-item prop="startAndEnd" label="创建时间">
          <a-range-picker v-model="form.startAndEnd"></a-range-picker>
        </a-form-model-item>
      </FilterLayout>
    </a-form-model>
  </a-card>
</template>

<script>
import FilterLayout from '@/layouts/FilterLayout.vue'

const FORM = () => ({
  realname: '',
  phone: '',
  startAndEnd: [undefined, undefined],
  roles: []
})

export default {
  components: {
    FilterLayout
  },
  data() {
    return {
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 18, offset: 1 }
      },
      form: FORM()
    }
  },
  computed: {},
  methods: {
    reset() {
      this.form = FORM()
      this.$nextTick(() => {
        this.handleSearch()
      })
    },
    handleSearch() {
      this.$emit('search')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

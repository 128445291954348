<!--
 * @Description: 个人中心-基本资料
-->
<template>
  <a-modal
    v-model="show"
    class="user_info_wrapper"
    width="900px"
    :title="`${actionStr}账号`"
    :confirmLoading="loading"
    @ok="handleSubmit"
  >
    <a-form-model
      ref="form"
      class="user_info_form"
      layout="horizontal"
      :model="form"
      :rules="rules"
      :labelCol="{span: 4 }"
      :wrapperCol="{ span: 20 }"
    >
      <a-form-model-item label="登录账号" prop="username">
        <a-input size="large" v-model="form.username" :disabled="isEdit" />
      </a-form-model-item>

      <a-form-model-item label="用户名称" prop="realname">
        <a-input size="large" v-model="form.realname" />
      </a-form-model-item>

      <a-form-model-item label="联系电话" prop="phone">
        <a-input size="large" v-model="form.phone" />
      </a-form-model-item>

      <a-form-model-item label="邮箱" prop="email">
        <a-input size="large" v-model="form.email" />
      </a-form-model-item>
      <a-form-model-item label="头像" prop="avatar">
        <AvatarEdit ref="modal" v-model="form.avatar" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import AvatarEdit from '@/components/AvatarEdit'
import { User } from '../_utils'
import { accountRule, realNameRuleFunc, phoneRule, emailRule } from '@/utils/rules'
import ApiController from '../api/account'

export default {
  components: {
    AvatarEdit
  },
  name: 'UserInfo',
  data() {
    return {
      show: false,
      loading: false,
      form: new User(),
      rules: {
        username: accountRule,
        realname: realNameRuleFunc('用户名称'),
        phone: phoneRule,
        email: emailRule,
        avatar: [{ required: true, message: '请上传头像', trigger: 'blur' }]
      }
    }
  },
  computed: {
    isEdit() {
      return this.form.id > 0
    },
    actionStr() {
      return this.isEdit ? '编辑' : '新增'
    }
  },
  methods: {
    async handleShow(item = {}) {
      this.show = true
      this.form = { ...new User(), ...item }
      await this.$nextTick()
      this.$refs.form.clearValidate()
    },
    async handleSubmit() {
      const apiMethod = this.isEdit ? ApiController.edit : ApiController.add
      const msg = this.isEdit ? '编辑成功!' : '新增成功,初始密码以短信的方式已发送至手机号'

      try {
        this.loading = true
        await this.$refs.form.validate()
        await apiMethod(this.form)
        this.$message.success(msg)
        this.$emit('addEditSuccess')
        this.show = false
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$avatarWidth: 150px;

.user_info_form {
  padding: 20px 40px;

  ::v-deep .a-input {
    max-width: 400px;
  }
}

// 更新按钮居右
.btn_wrapper {
  text-align: right;
}

.avatar_col {
  display: flex;
  justify-content: flex-end;
}

// 头像
.ant-upload-preview {
  position: relative;
  width: 100%;
  max-width: 180px;
  border-radius: 50%;
  box-shadow: 0 0 4px #ccc;

  .upload-icon {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.4rem;
    padding: 0.5rem;
    background: rgba(222, 221, 221, 0.7);
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  .mask {
    opacity: 0;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    cursor: pointer;
    transition: opacity 0.4s;

    &:hover {
      opacity: 1;
    }

    i {
      font-size: 2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -1rem;
      margin-top: -1rem;
      color: #d6d6d6;
    }
  }

  img,
  .mask {
    width: 100%;
    max-width: 180px;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
}
</style>

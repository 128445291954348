/*
 * @Description: 默认表单layout
 */

// 默认的表单布局
const DEFAULT_FORM_LAYOUT = {
    labelCol: { span: 6 },
    wrapperCol: { span: 16 }
}

// 筛选区域layout
const FILTER_FORM_LAYOUT = {
  labelCol: { span: 6 },
  wrapperCol: { span: 17, offset: 1 }
}

export {
  FILTER_FORM_LAYOUT,
  DEFAULT_FORM_LAYOUT
}

export default DEFAULT_FORM_LAYOUT

/*
 * @Description: 表单验证rule
 */
import { USERNAME_REG, REALNAME_REG, PHONE_REG, EMAIL_REG, ORG_NAME_REG } from './RegExp'

// 账号rule
const accountRule = [
  { required: true, message: '请输入用户名', trigger: 'blur' }, // 非空校验
  // 格式校验
  { pattern: USERNAME_REG, message: '请输入8-15用户名，必须包含字母和数字', trigger: 'blur' }
]

// 真实姓名rule
const realNameRule = [
  { required: true, message: '请输入姓名', trigger: 'blur' }, // 非空校验
  // 格式校验
  { pattern: REALNAME_REG, message: '请输入中文或者英文姓名，10个字以内', trigger: 'blur' }
]

const realNameRuleFunc = (alias = '姓名') => {
  return [
    { required: true, message: `请输入${alias}`, trigger: 'blur' }, // 非空校验
    // 格式校验
    { pattern: REALNAME_REG, message: `请输入中文或者英文${alias}，10个字以内`, trigger: 'blur' }
  ]
}

// 手机号rule
const phoneRule = [
  { required: true, message: '请输入手机号', trigger: 'blur' },
  { required: true, pattern: PHONE_REG, message: '手机号格式错误', trigger: 'blur' }
]

// 邮箱rule
const emailRule = [
  { required: true, message: '请输入邮箱', trigger: 'blur' },
  { required: true, pattern: EMAIL_REG, message: '邮箱格式错误', trigger: 'blur' }
]

// 单位名称rul
const orgNameRule = [
  { required: true, trigger: 'blur', message: '名称不能为空' },
  { pattern: ORG_NAME_REG, message: '可输入数字，汉字或者大小写字母，30个字以内', trigger: 'blur' }
]

const priceValidator = (max) => (rule, value, callback) => {
  // eslint-disable-next-line
  if (value == undefined || value === '') {
    callback(new Error('不允许为空值'))
  } else if (/\.\d+$/.test(String(value))) {
    callback(new Error('只允许输入两位小数'))
  } else if (value / 100 > max) {
    callback(new Error(`超过最大允许值${max}`))
  } else {
    callback()
  }
}

// 单价或者距离的输入
const priceValRule = (alias = '该项内容', max = 99999999) => [
  { required: true, trigger: 'blur', message: `请输入${alias}` },
  { validator: priceValidator(max), trigger: 'blur' }
]

const NotEmptyRule = { required: true, trigger: 'blur', message: '请完善该项内容' }

export {
  accountRule,
  realNameRule,
  realNameRuleFunc,
  phoneRule,
  emailRule,
  orgNameRule,
  NotEmptyRule,
  priceValRule
}

/*
 * @Description: 账户相关接口
 */
import { requestCustom as request } from '@/utils/request'

const BASE_URL = `/api/users`

// 获取用户列表
function getAllAuthUsers(data) {
  return request({
    url: `${BASE_URL}/getAllAuthUsers`,
    method: 'post',
    data
  })
}

// 添加用户
function add(data) {
  return request({
    url: `${BASE_URL}`,
    method: 'post',
    data
  })
}

// 编辑用户
function edit(data) {
  return request({
    url: `${BASE_URL}`,
    method: 'put',
    data
  })
}

// 删除用户
function deleteItem(id) {
  return request({
    url: `${BASE_URL}/${id}`,
    method: 'delete'
  })
}

// 分配角色
function assignRole(data) {
  return request({
    url: `${BASE_URL}/assign-role/${data.id}`,
    method: 'post',
    data: data.roleIds
  })
}

// 重置密码
function resetUserPassword(data) {
  return request({
    url: `${BASE_URL}/resetUserPassword/${data.id}`,
    method: 'put'
  })
}

// 获取角色信息列表
function getAllAuthRoles(params) {
  return request({
    method: 'POST',
    url: `/api/auth-roles/getAllAuthRoles`,
    params,
    data: {}
  })
}

export default {
  getAllAuthUsers,
  add,
  edit,
  deleteItem,
  assignRole,
  resetUserPassword,
  getAllAuthRoles
}

<!--
 * @Description: 主要表格
-->

<template>
  <div class="table_area_wrapper">
    <!-- 表格加一层wrapper, 方便 height=auto 处理 -->
    <div class="custom_a_table_wrapper">
      <vxe-table height="auto" :data="tableData" :loading="loading">
        <vxe-column title="登录账号" field="username"></vxe-column>
        <vxe-column title="用户名称" field="realname"></vxe-column>
        <vxe-column title="联系电话" field="phone"></vxe-column>
        <vxe-column title="邮箱" field="email"></vxe-column>
        <vxe-column title="用户角色" field="role">
          <template #default="{row}">
            <a-tag v-for="(role, index) in row.authRolevms" :key="index">{{ role.name }}</a-tag>
          </template>
        </vxe-column>
        <vxe-column title="创建时间" field="createTime"></vxe-column>
        <vxe-column title="操作" field="action" align="center">
          <template #default="{row}">
            <a-button type="link" v-auth:EDIT_USER @click="() => handleAddEditItem(row)">编辑</a-button>
            <a-button type="link" v-auth:EDIT_USER @click="() => handleSetRole(row)">编辑权限</a-button>
            <a-button type="link" v-auth:EDIT_USER @click="() => handleResetPassword(row)">重置密码</a-button>
            <a-button type="link" v-auth:EDIT_USER @click="() => handleDeleteItem(row)">删除</a-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!-- 分页 -->
    <Pagination :pagination.sync="pagination" @change="fetchData" />

    <!-- 添加编辑账号 -->
    <AddEditItemDialog ref="AddEditItemDialog" @addEditSuccess="fetchData"></AddEditItemDialog>

    <!-- 编辑角色弹窗 -->
    <SelectRoleDialog ref="SelectRoleDialog" @success="fetchData"></SelectRoleDialog>
  </div>
</template>

<script>
import AddEditItemDialog from '../dialogs/AddEditItemDialog.vue'
import SelectRoleDialog from '../dialogs/SelectRoleDialog.vue'
import ApiController from '../api/account'
import { getFilterParams } from '@/utils/utils.custom'

export default {
  components: {
    AddEditItemDialog,
    SelectRoleDialog
  },
  props: {
    getFilter: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0
      },
      tableData: []
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // 拉取数据
    async fetchData() {
      const params = getFilterParams.call(this)
      try {
        this.loading = true
        const { totalElements, content } = await ApiController.getAllAuthUsers(params)
        this.tableData = content
        this.pagination.total = totalElements
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    // 添加编辑账号
    handleAddEditItem(row = {}) {
      this.$refs.AddEditItemDialog.handleShow(row)
    },
    // 删除用户
    handleDeleteItem(row) {
      const _deleteAction = async () => {
        try {
          await ApiController.deleteItem(row.id)
          this.fetchData()
        } catch (error) {
          console.log(error)
        }
      }

      const title = (h) => {
        return (
          <div>
            <span>确定要删除该用户么？</span>
            <b style="color: #C03639;">删除之后不可恢复!</b>
          </div>
        )
      }

      this.$confirm({
        type: 'warning',
        title: title,
        onOk: _deleteAction
      })
    },
    // 重置密码
    handleResetPassword(row) {
      const _action = async () => {
        try {
          await ApiController.resetUserPassword(row)
          this.$info({
            content: '重置成功,密码以短信的方式已发送至绑定手机号'
          })
          this.fetchData()
        } catch (error) {
          console.log(error)
        }
      }

      this.$confirm({
        type: 'warning',
        title: '确定要重置密码吗？',
        onOk: _action
      })
    },
    // 编辑权限(选择角色)
    handleSetRole(item) {
      this.$refs.SelectRoleDialog.handleShow(item)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

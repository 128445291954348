import './index.less'
import { Upload, Icon, Button, Modal, Avatar } from 'ant-design-vue'
// , Progress
import { VueCropper } from 'vue-cropper'
import request from '@/utils/request'
// import axios from 'axios'
export default {
  name: 'AvatarEdit',
  model: {
    prop: 'value',
    event: 'change',
    recWidth: 'recWidth',
    recHeight: 'recHeight',
    accept: 'accept'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    recWidth: {
      type: Number,
      default: 160
    },
    recHeight: {
      type: Number,
      default: 160
    },
    accept: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dRecWidth: 160,
      dRecHeight: 160,
      dAccept: 'jpg/jpeg/png',
      uploadPercent: 0,
      isUploading: false,
      previewVisible: false,
      tempFileList: [],
      waitUploadFileList: [],
      tempBase64Img: '',
      imgWidth: 0,
      imgHeight: 0,
      clippingModalShow: false,
      maxWidth: 1000
    }
  },
  watch: {
    recWidth: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        this.dRecWidth = newV || 160
      }
    },
    recHeight: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        this.dRecHeight = newV || 160
      }
    },
    accept: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        this.dAccept = newV || 'jpg/jpeg/png'
      }
    },
    value: {
      deep: true,
      immediate: true,
      handler: function (newV) {
        if (newV === null || newV === '' || newV === undefined) {
          this.tempFileList = []
          return
        }
        let count = -1
        let temp = []
        const tempList = []
        temp = newV.split(',')
        temp.forEach(item => {
          tempList.push({
            uid: count,
            name: item,
            status: 'done',
            url: `${item}?t=${new Date().getTime()}`
          })
          count--
        })
        this.tempFileList = tempList
      }
    }
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
    })
  },
  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    handelBeforeUpload(file, fileList) {
      this.uploadPercent = 0
      this.isUploading = false
      // this.tempFileList = fileList
      var _this = this
      this.clippingModalShow = true
      if (window.FileReader) {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = function (e) {
          var image = new Image()
          image.onload = function () {
            _this.imgWidth = this.width > _this.maxWidth ? _this.maxWidth : this.width
            _this.imgHeight = this.width > _this.maxWidth * 9 / 16 ? _this.maxWidth * 9 / 16 : this.width
          }
          image.src = e.target.result
        }
      }
      this.waitUploadFileList.push(file)
      this.getBase64(file).then((res) => {
        this.tempBase64Img = res
      })
      return false
    },
    handelClippingImg(todo) {
      var _this = this
      if (todo === 'clipping') {
        // this.uploadPercent = 0
        this.isUploading = true
        _this.clippingModalShow = false
        this.$refs.cropper.getCropData(async (data) => {
          var res = await request({
            method: 'POST',
            url: '/api/file',
            formData: { base64: data },
            onUploadProgress: progressEvent => {
              var complete = (progressEvent.loaded / progressEvent.total * 100 | 0)
              this.uploadPercent = complete
              if (complete === 100) {
                this.uploadPercent = 100
                this.isUploading = false
              }
            }
          })
          _this.tempFileList = [{ uid: -1, ...res }]
          _this.$emit('change', res.url)
        }
        )
      } else if (todo === 'cancel') {
        this.uploadPercent = 0
        this.isUploading = false
        this.clippingModalShow = false
      }
    },
    handelPreview(file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
    handelRemove(file) {
      this.uploadPercent = 0
      this.isUploading = false
      request({
        method: 'DELETE',
        url: '/api/file',
        formData: { path: file.url.split('?')[0] }
      }).then((res) => {
        this.$emit('change', '')
      })
      return false
    }
  },
  render() {
    return (
      <div class='AvatarEdit'>
        <Modal title='图片预览' visible={this.previewVisible} footer={null} onCancel={() => {
          this.previewVisible = false
        }}>
          <img alt="example" style={{ width: '100%' }} src={this.previewImage} />
        </Modal>
        <Modal title='图片剪裁'
          maskClosable={false}
          closable={false}
          footer={null}
          visible={this.clippingModalShow}
          width={this.imgWidth + 40}>
          <div
            style={{
              width: this.imgWidth + 'px',
              height: this.imgHeight + 'px',
              marginBottom: '20px'
            }}>
            <VueCropper
              ref='cropper'
              outputType={'jpeg'}
              img={this.tempBase64Img}
              fixed={true}
              fixedNumber={[this.recWidth, this.recHeight]}
              autoCropWidth={this.imgWidth}
              autoCropHeight={this.imgWidth * this.recHeight / this.recWidth}
              info={true}
              autoCrop={true}
              canMove={false}
              canScale={false}
            />
          </div>
          <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}>
            <Button onClick={this.handelClippingImg.bind(this, 'cancel')}>取消</Button>
            <Button type='primary' style={{ marginLeft: '10px' }}
              onClick={
                this.handelClippingImg.bind(this, 'clipping')
              }>确定</Button>
          </div>
        </Modal>
        <div style={{ width: '131px' }}>
          <Upload
            showUploadList={false}
            beforeUpload={this.handelBeforeUpload.bind(this)}
            accept={
              this.dAccept.split('/').map(item => {
                return `image/${item}`
              }).join(',')
            }
            onPreview={this.handelPreview.bind(this)}
            remove={this.handelRemove.bind(this)}
          >
            <div class='box'>
              <Avatar size={180} src={this.tempFileList && this.tempFileList.length > 0 ? this.tempFileList[0].url : ''} alt='未设置' />
              <Button style={{ marginTop: '20px' }}><Icon type='upload' />更换头像</Button>
            </div>
          </Upload>
        </div>
      </div >)
  }
}

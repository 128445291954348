var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    [
      _c(
        "ContentLayout",
        [
          _c("FilterForm", {
            ref: "FilterForm",
            attrs: { slot: "top" },
            on: { search: _vm.handleSearch },
            slot: "top",
          }),
          _c(
            "div",
            { staticClass: "btn_wrapper mb-10" },
            [
              _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "auth",
                      rawName: "v-auth:EDIT_USER",
                      arg: "EDIT_USER",
                    },
                  ],
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.handleAddItem },
                },
                [_vm._v("新增账号")]
              ),
            ],
            1
          ),
          _c("MainTable", {
            ref: "MainTable",
            staticClass: "table_area_comp",
            attrs: { getFilter: _vm.getFilter },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
/*
 * @Description: 文件描述
 */
import defaultConfig from '@/config/defaultSettings'

// 用户类
class User {
  constructor() {
    this.__init()
  }

  __init() {
    this.username = ''
    this.realname = ''
    this.phone = ''
    this.email = ''
    this.avatar = defaultConfig.avatar
  }
}

export {
  User
}

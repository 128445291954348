/*
 * @Description: 自定义的一些函数
 */
import formatTime from '@/utils/date'

function getFilterParams() {
  try {
    const filterForm = this.getFilter()
    const [ createTimeFrom, createTimeTo ] = filterForm.startAndEnd || []
    const params = {
      ...filterForm,
      ...this.pagination,
      createTimeFrom: formatTime(createTimeFrom),
      createTimeTo: formatTime(createTimeTo),
      pageNumber: this.pagination.current,
      total: undefined
    }
    return params
  } catch (error) {
    console.log(error)
    return {}
  }
}

export {
  getFilterParams
}

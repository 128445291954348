/*
 * @Description: 正则表达式集合
 */

// 密码(8-16位，字母或者数字)
const PASSWORD_REG = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{8,16}$/
// 用户名(8-16位，字母或者数字)
const USERNAME_REG = /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z]{8,15}$/
// 真实姓名
const REALNAME_REG = /(^[\u4E00-\u9FA5a-zA-Z\s]{2,10}$)/
// 手机号
const PHONE_REG = /^((\+?86)|(\(\+86\)))?((((13[^4]{1})|(14[5-9]{1})|147|(15[^4]{1})|166|(17\d{1})|(18\d{1})|(19[12589]{1}))\d{8})|((134[^9]{1}|1410|1440)\d{7}))$/
// 邮箱格式
const EMAIL_REG = /[\w\-_]+@[a-zA-Z0-9\\-]{0,61}\.[a-zA-Z]{2,6}$/
// 机构名称格式
const ORG_NAME_REG = /^[\u4E00-\u9FA5a-zA-Z\d]{1,30}$/

export {
  PASSWORD_REG,
  USERNAME_REG,
  REALNAME_REG,
  PHONE_REG,
  EMAIL_REG,
  ORG_NAME_REG
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      staticClass: "user_info_wrapper",
      attrs: {
        width: "900px",
        title: _vm.actionStr + "账号",
        confirmLoading: _vm.loading,
      },
      on: { ok: _vm.handleSubmit },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          staticClass: "user_info_form",
          attrs: {
            layout: "horizontal",
            model: _vm.form,
            rules: _vm.rules,
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "登录账号", prop: "username" } },
            [
              _c("a-input", {
                attrs: { size: "large", disabled: _vm.isEdit },
                model: {
                  value: _vm.form.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "username", $$v)
                  },
                  expression: "form.username",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用户名称", prop: "realname" } },
            [
              _c("a-input", {
                attrs: { size: "large" },
                model: {
                  value: _vm.form.realname,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "realname", $$v)
                  },
                  expression: "form.realname",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "联系电话", prop: "phone" } },
            [
              _c("a-input", {
                attrs: { size: "large" },
                model: {
                  value: _vm.form.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "邮箱", prop: "email" } },
            [
              _c("a-input", {
                attrs: { size: "large" },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "头像", prop: "avatar" } },
            [
              _c("AvatarEdit", {
                ref: "modal",
                model: {
                  value: _vm.form.avatar,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "avatar", $$v)
                  },
                  expression: "form.avatar",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }